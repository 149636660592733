@charset "UTF-8";
.flag-icon-background, .flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.flag-icon {
  position: relative;
  display: inline-block;
  width: 19px;
  height: 15px;
  line-height: 1em;
  border: 1px solid var(--bs-gray-300);
}
@media (min-width: 768px) {
  .flag-icon {
    width: 23px;
    height: 18px;
  }
}
.flag-icon:before {
  content: " ";
}
.flag-icon.flag-icon-squared {
  width: 1em;
}

.flag-icon-abw {
  background-image: url(/images/flags/4x3/abw.png);
}

.flag-icon-afg {
  background-image: url(/images/flags/4x3/afg.png);
}

.flag-icon-ago {
  background-image: url(/images/flags/4x3/ago.png);
}

.flag-icon-aia {
  background-image: url(/images/flags/4x3/aia.png);
}

.flag-icon-ala {
  background-image: url(/images/flags/4x3/ala.png);
}

.flag-icon-alb {
  background-image: url(/images/flags/4x3/alb.png);
}

.flag-icon-and {
  background-image: url(/images/flags/4x3/and.png);
}

.flag-icon-are {
  background-image: url(/images/flags/4x3/are.png);
}

.flag-icon-arg {
  background-image: url(/images/flags/4x3/arg.png);
}

.flag-icon-arm {
  background-image: url(/images/flags/4x3/arm.png);
}

.flag-icon-asm {
  background-image: url(/images/flags/4x3/asm.png);
}

.flag-icon-ata {
  background-image: url(/images/flags/4x3/ata.png);
}

.flag-icon-atf {
  background-image: url(/images/flags/4x3/atf.png);
}

.flag-icon-atg {
  background-image: url(/images/flags/4x3/atg.png);
}

.flag-icon-aus {
  background-image: url(/images/flags/4x3/aus.png);
}

.flag-icon-aut {
  background-image: url(/images/flags/4x3/aut.png);
}

.flag-icon-aze {
  background-image: url(/images/flags/4x3/aze.png);
}

.flag-icon-bdi {
  background-image: url(/images/flags/4x3/bdi.png);
}

.flag-icon-bel {
  background-image: url(/images/flags/4x3/bel.png);
}

.flag-icon-ben {
  background-image: url(/images/flags/4x3/ben.png);
}

.flag-icon-bes {
  background-image: url(/images/flags/4x3/bes.png);
}

.flag-icon-bfa {
  background-image: url(/images/flags/4x3/bfa.png);
}

.flag-icon-bgd {
  background-image: url(/images/flags/4x3/bgd.png);
}

.flag-icon-bgr {
  background-image: url(/images/flags/4x3/bgr.png);
}

.flag-icon-bhr {
  background-image: url(/images/flags/4x3/bhr.png);
}

.flag-icon-bhs {
  background-image: url(/images/flags/4x3/bhs.png);
}

.flag-icon-bih {
  background-image: url(/images/flags/4x3/bih.png);
}

.flag-icon-blm {
  background-image: url(/images/flags/4x3/blm.png);
}

.flag-icon-blr {
  background-image: url(/images/flags/4x3/blr.png);
}

.flag-icon-blz {
  background-image: url(/images/flags/4x3/blz.png);
}

.flag-icon-bmu {
  background-image: url(/images/flags/4x3/bmu.png);
}

.flag-icon-bol {
  background-image: url(/images/flags/4x3/bol.png);
}

.flag-icon-bra {
  background-image: url(/images/flags/4x3/bra.png);
}

.flag-icon-brb {
  background-image: url(/images/flags/4x3/brb.png);
}

.flag-icon-brn {
  background-image: url(/images/flags/4x3/brn.png);
}

.flag-icon-btn {
  background-image: url(/images/flags/4x3/btn.png);
}

.flag-icon-bvt {
  background-image: url(/images/flags/4x3/bvt.png);
}

.flag-icon-bwa {
  background-image: url(/images/flags/4x3/bwa.png);
}

.flag-icon-caf {
  background-image: url(/images/flags/4x3/caf.png);
}

.flag-icon-can {
  background-image: url(/images/flags/4x3/can.png);
}

.flag-icon-cat {
  background-image: url(/images/flags/4x3/cat.png);
}

.flag-icon-cck {
  background-image: url(/images/flags/4x3/cck.png);
}

.flag-icon-sui {
  background-image: url(/images/flags/4x3/sui.png);
}

.flag-icon-chl {
  background-image: url(/images/flags/4x3/chl.png);
}

.flag-icon-chn {
  background-image: url(/images/flags/4x3/chn.png);
}

.flag-icon-civ {
  background-image: url(/images/flags/4x3/civ.png);
}

.flag-icon-cmr {
  background-image: url(/images/flags/4x3/cmr.png);
}

.flag-icon-cod {
  background-image: url(/images/flags/4x3/cod.png);
}

.flag-icon-cog {
  background-image: url(/images/flags/4x3/cog.png);
}

.flag-icon-cok {
  background-image: url(/images/flags/4x3/cok.png);
}

.flag-icon-col {
  background-image: url(/images/flags/4x3/col.png);
}

.flag-icon-com {
  background-image: url(/images/flags/4x3/com.png);
}

.flag-icon-cpv {
  background-image: url(/images/flags/4x3/cpv.png);
}

.flag-icon-cri {
  background-image: url(/images/flags/4x3/cri.png);
}

.flag-icon-cub {
  background-image: url(/images/flags/4x3/cub.png);
}

.flag-icon-cuw {
  background-image: url(/images/flags/4x3/cuw.png);
}

.flag-icon-cxr {
  background-image: url(/images/flags/4x3/cxr.png);
}

.flag-icon-cym {
  background-image: url(/images/flags/4x3/cym.png);
}

.flag-icon-cyp {
  background-image: url(/images/flags/4x3/cyp.png);
}

.flag-icon-cze {
  background-image: url(/images/flags/4x3/cze.png);
}

.flag-icon-ger {
  background-image: url(/images/flags/4x3/ger.png);
}

.flag-icon-dji {
  background-image: url(/images/flags/4x3/dji.png);
}

.flag-icon-dma {
  background-image: url(/images/flags/4x3/dma.png);
}

.flag-icon-den {
  background-image: url(/images/flags/4x3/den.png);
}

.flag-icon-dom {
  background-image: url(/images/flags/4x3/dom.png);
}

.flag-icon-dza {
  background-image: url(/images/flags/4x3/dza.png);
}

.flag-icon-ecu {
  background-image: url(/images/flags/4x3/ecu.png);
}

.flag-icon-egy {
  background-image: url(/images/flags/4x3/egy.png);
}

.flag-icon-eng {
  background-image: url(/images/flags/4x3/eng.png);
}

.flag-icon-eri {
  background-image: url(/images/flags/4x3/eri.png);
}

.flag-icon-esh {
  background-image: url(/images/flags/4x3/esh.png);
}

.flag-icon-esp {
  background-image: url(/images/flags/4x3/esp.png);
}

.flag-icon-est {
  background-image: url(/images/flags/4x3/est.png);
}

.flag-icon-eth {
  background-image: url(/images/flags/4x3/eth.png);
}

.flag-icon-eun {
  background-image: url(/images/flags/4x3/eun.png);
}

.flag-icon-fin {
  background-image: url(/images/flags/4x3/fin.png);
}

.flag-icon-fji {
  background-image: url(/images/flags/4x3/fji.png);
}

.flag-icon-flk {
  background-image: url(/images/flags/4x3/flk.png);
}

.flag-icon-fra {
  background-image: url(/images/flags/4x3/fra.png);
}

.flag-icon-fro {
  background-image: url(/images/flags/4x3/fro.png);
}

.flag-icon-fsm {
  background-image: url(/images/flags/4x3/fsm.png);
}

.flag-icon-gab {
  background-image: url(/images/flags/4x3/gab.png);
}

.flag-icon-gbr {
  background-image: url(/images/flags/4x3/gbr.png);
}

.flag-icon-geo {
  background-image: url(/images/flags/4x3/geo.png);
}

.flag-icon-ggy {
  background-image: url(/images/flags/4x3/ggy.png);
}

.flag-icon-gha {
  background-image: url(/images/flags/4x3/gha.png);
}

.flag-icon-gib {
  background-image: url(/images/flags/4x3/gib.png);
}

.flag-icon-gin {
  background-image: url(/images/flags/4x3/gin.png);
}

.flag-icon-glp {
  background-image: url(/images/flags/4x3/glp.png);
}

.flag-icon-gmb {
  background-image: url(/images/flags/4x3/gmb.png);
}

.flag-icon-gnb {
  background-image: url(/images/flags/4x3/gnb.png);
}

.flag-icon-gnq {
  background-image: url(/images/flags/4x3/gnq.png);
}

.flag-icon-grc {
  background-image: url(/images/flags/4x3/grc.png);
}

.flag-icon-grd {
  background-image: url(/images/flags/4x3/grd.png);
}

.flag-icon-grl {
  background-image: url(/images/flags/4x3/grl.png);
}

.flag-icon-gtm {
  background-image: url(/images/flags/4x3/gtm.png);
}

.flag-icon-guf {
  background-image: url(/images/flags/4x3/guf.png);
}

.flag-icon-gum {
  background-image: url(/images/flags/4x3/gum.png);
}

.flag-icon-guy {
  background-image: url(/images/flags/4x3/guy.png);
}

.flag-icon-hkg {
  background-image: url(/images/flags/4x3/hkg.png);
}

.flag-icon-hmd {
  background-image: url(/images/flags/4x3/hmd.png);
}

.flag-icon-hnd {
  background-image: url(/images/flags/4x3/hnd.png);
}

.flag-icon-hrv {
  background-image: url(/images/flags/4x3/hrv.png);
}

.flag-icon-hti {
  background-image: url(/images/flags/4x3/hti.png);
}

.flag-icon-hun {
  background-image: url(/images/flags/4x3/hun.png);
}

.flag-icon-idn {
  background-image: url(/images/flags/4x3/idn.png);
}

.flag-icon-imn {
  background-image: url(/images/flags/4x3/imn.png);
}

.flag-icon-ind {
  background-image: url(/images/flags/4x3/ind.png);
}

.flag-icon-iot {
  background-image: url(/images/flags/4x3/iot.png);
}

.flag-icon-irl {
  background-image: url(/images/flags/4x3/irl.png);
}

.flag-icon-irn {
  background-image: url(/images/flags/4x3/irn.png);
}

.flag-icon-irq {
  background-image: url(/images/flags/4x3/irq.png);
}

.flag-icon-isl {
  background-image: url(/images/flags/4x3/isl.png);
}

.flag-icon-isr {
  background-image: url(/images/flags/4x3/isr.png);
}

.flag-icon-ita {
  background-image: url(/images/flags/4x3/ita.png);
}

.flag-icon-jam {
  background-image: url(/images/flags/4x3/jam.png);
}

.flag-icon-jey {
  background-image: url(/images/flags/4x3/jey.png);
}

.flag-icon-jor {
  background-image: url(/images/flags/4x3/jor.png);
}

.flag-icon-jpn {
  background-image: url(/images/flags/4x3/jpn.png);
}

.flag-icon-kaz {
  background-image: url(/images/flags/4x3/kaz.png);
}

.flag-icon-ken {
  background-image: url(/images/flags/4x3/ken.png);
}

.flag-icon-kgz {
  background-image: url(/images/flags/4x3/kgz.png);
}

.flag-icon-khm {
  background-image: url(/images/flags/4x3/khm.png);
}

.flag-icon-kir {
  background-image: url(/images/flags/4x3/kir.png);
}

.flag-icon-kna {
  background-image: url(/images/flags/4x3/kna.png);
}

.flag-icon-kor {
  background-image: url(/images/flags/4x3/kor.png);
}

.flag-icon-kos {
  background-image: url(/images/flags/4x3/kos.png);
}

.flag-icon-kwt {
  background-image: url(/images/flags/4x3/kwt.png);
}

.flag-icon-lao {
  background-image: url(/images/flags/4x3/lao.png);
}

.flag-icon-lbn {
  background-image: url(/images/flags/4x3/lbn.png);
}

.flag-icon-lbr {
  background-image: url(/images/flags/4x3/lbr.png);
}

.flag-icon-lby {
  background-image: url(/images/flags/4x3/lby.png);
}

.flag-icon-lca {
  background-image: url(/images/flags/4x3/lca.png);
}

.flag-icon-lie {
  background-image: url(/images/flags/4x3/lie.png);
}

.flag-icon-lka {
  background-image: url(/images/flags/4x3/lka.png);
}

.flag-icon-lso {
  background-image: url(/images/flags/4x3/lso.png);
}

.flag-icon-ltu {
  background-image: url(/images/flags/4x3/ltu.png);
}

.flag-icon-lux {
  background-image: url(/images/flags/4x3/lux.png);
}

.flag-icon-lva {
  background-image: url(/images/flags/4x3/lva.png);
}

.flag-icon-mac {
  background-image: url(/images/flags/4x3/mac.png);
}

.flag-icon-maf {
  background-image: url(/images/flags/4x3/maf.png);
}

.flag-icon-mar {
  background-image: url(/images/flags/4x3/mar.png);
}

.flag-icon-mco {
  background-image: url(/images/flags/4x3/mco.png);
}

.flag-icon-mda {
  background-image: url(/images/flags/4x3/mda.png);
}

.flag-icon-mdg {
  background-image: url(/images/flags/4x3/mdg.png);
}

.flag-icon-mdv {
  background-image: url(/images/flags/4x3/mdv.png);
}

.flag-icon-mex {
  background-image: url(/images/flags/4x3/mex.png);
}

.flag-icon-mhl {
  background-image: url(/images/flags/4x3/mhl.png);
}

.flag-icon-mkd {
  background-image: url(/images/flags/4x3/mkd.png);
}

.flag-icon-mli {
  background-image: url(/images/flags/4x3/mli.png);
}

.flag-icon-mlt {
  background-image: url(/images/flags/4x3/mlt.png);
}

.flag-icon-mmr {
  background-image: url(/images/flags/4x3/mmr.png);
}

.flag-icon-mne {
  background-image: url(/images/flags/4x3/mne.png);
}

.flag-icon-mng {
  background-image: url(/images/flags/4x3/mng.png);
}

.flag-icon-mnp {
  background-image: url(/images/flags/4x3/mnp.png);
}

.flag-icon-moz {
  background-image: url(/images/flags/4x3/moz.png);
}

.flag-icon-mrt {
  background-image: url(/images/flags/4x3/mrt.png);
}

.flag-icon-msr {
  background-image: url(/images/flags/4x3/msr.png);
}

.flag-icon-mtq {
  background-image: url(/images/flags/4x3/mtq.png);
}

.flag-icon-mus {
  background-image: url(/images/flags/4x3/mus.png);
}

.flag-icon-mwi {
  background-image: url(/images/flags/4x3/mwi.png);
}

.flag-icon-mas {
  background-image: url(/images/flags/4x3/mas.png);
}

.flag-icon-myt {
  background-image: url(/images/flags/4x3/myt.png);
}

.flag-icon-nam {
  background-image: url(/images/flags/4x3/nam.png);
}

.flag-icon-ncl {
  background-image: url(/images/flags/4x3/ncl.png);
}

.flag-icon-ner {
  background-image: url(/images/flags/4x3/ner.png);
}

.flag-icon-nfk {
  background-image: url(/images/flags/4x3/nfk.png);
}

.flag-icon-ngr {
  background-image: url(/images/flags/4x3/ngr.png);
}

.flag-icon-nic {
  background-image: url(/images/flags/4x3/nic.png);
}

.flag-icon-nir {
  background-image: url(/images/flags/4x3/nir.png);
}

.flag-icon-niu {
  background-image: url(/images/flags/4x3/niu.png);
}

.flag-icon-ned {
  background-image: url(/images/flags/4x3/ned.png);
}

.flag-icon-nor {
  background-image: url(/images/flags/4x3/nor.png);
}

.flag-icon-npl {
  background-image: url(/images/flags/4x3/npl.png);
}

.flag-icon-nru {
  background-image: url(/images/flags/4x3/nru.png);
}

.flag-icon-nzl {
  background-image: url(/images/flags/4x3/nzl.png);
}

.flag-icon-omn {
  background-image: url(/images/flags/4x3/omn.png);
}

.flag-icon-pak {
  background-image: url(/images/flags/4x3/pak.png);
}

.flag-icon-pan {
  background-image: url(/images/flags/4x3/pan.png);
}

.flag-icon-pcn {
  background-image: url(/images/flags/4x3/pcn.png);
}

.flag-icon-per {
  background-image: url(/images/flags/4x3/per.png);
}

.flag-icon-phl {
  background-image: url(/images/flags/4x3/phl.png);
}

.flag-icon-plw {
  background-image: url(/images/flags/4x3/plw.png);
}

.flag-icon-png {
  background-image: url(/images/flags/4x3/png.png);
}

.flag-icon-pol {
  background-image: url(/images/flags/4x3/pol.png);
}

.flag-icon-pri {
  background-image: url(/images/flags/4x3/pri.png);
}

.flag-icon-prk {
  background-image: url(/images/flags/4x3/prk.png);
}

.flag-icon-por {
  background-image: url(/images/flags/4x3/por.png);
}

.flag-icon-pry {
  background-image: url(/images/flags/4x3/pry.png);
}

.flag-icon-pse {
  background-image: url(/images/flags/4x3/pse.png);
}

.flag-icon-pyf {
  background-image: url(/images/flags/4x3/pyf.png);
}

.flag-icon-qat {
  background-image: url(/images/flags/4x3/qat.png);
}

.flag-icon-reu {
  background-image: url(/images/flags/4x3/reu.png);
}

.flag-icon-rou {
  background-image: url(/images/flags/4x3/rou.png);
}

.flag-icon-rus {
  background-image: url(/images/flags/4x3/rus.png);
}

.flag-icon-rwa {
  background-image: url(/images/flags/4x3/rwa.png);
}

.flag-icon-sau {
  background-image: url(/images/flags/4x3/sau.png);
}

.flag-icon-sco {
  background-image: url(/images/flags/4x3/sco.png);
}

.flag-icon-sdn {
  background-image: url(/images/flags/4x3/sdn.png);
}

.flag-icon-sen {
  background-image: url(/images/flags/4x3/sen.png);
}

.flag-icon-sgp {
  background-image: url(/images/flags/4x3/sgp.png);
}

.flag-icon-sgs {
  background-image: url(/images/flags/4x3/sgs.png);
}

.flag-icon-shn {
  background-image: url(/images/flags/4x3/shn.png);
}

.flag-icon-sjm {
  background-image: url(/images/flags/4x3/sjm.png);
}

.flag-icon-slb {
  background-image: url(/images/flags/4x3/slb.png);
}

.flag-icon-sle {
  background-image: url(/images/flags/4x3/sle.png);
}

.flag-icon-slv {
  background-image: url(/images/flags/4x3/slv.png);
}

.flag-icon-smr {
  background-image: url(/images/flags/4x3/smr.png);
}

.flag-icon-som {
  background-image: url(/images/flags/4x3/som.png);
}

.flag-icon-spm {
  background-image: url(/images/flags/4x3/spm.png);
}

.flag-icon-srb {
  background-image: url(/images/flags/4x3/srb.png);
}

.flag-icon-ssd {
  background-image: url(/images/flags/4x3/ssd.png);
}

.flag-icon-stp {
  background-image: url(/images/flags/4x3/stp.png);
}

.flag-icon-sun {
  background-image: url(/images/flags/4x3/sun.png);
}

.flag-icon-sur {
  background-image: url(/images/flags/4x3/sur.png);
}

.flag-icon-svk {
  background-image: url(/images/flags/4x3/svk.png);
}

.flag-icon-slo {
  background-image: url(/images/flags/4x3/slo.png);
}

.flag-icon-swe {
  background-image: url(/images/flags/4x3/swe.png);
}

.flag-icon-swz {
  background-image: url(/images/flags/4x3/swz.png);
}

.flag-icon-sxm {
  background-image: url(/images/flags/4x3/sxm.png);
}

.flag-icon-syc {
  background-image: url(/images/flags/4x3/syc.png);
}

.flag-icon-syr {
  background-image: url(/images/flags/4x3/syr.png);
}

.flag-icon-tca {
  background-image: url(/images/flags/4x3/tca.png);
}

.flag-icon-tcd {
  background-image: url(/images/flags/4x3/tcd.png);
}

.flag-icon-tgo {
  background-image: url(/images/flags/4x3/tgo.png);
}

.flag-icon-tha {
  background-image: url(/images/flags/4x3/tha.png);
}

.flag-icon-tjk {
  background-image: url(/images/flags/4x3/tjk.png);
}

.flag-icon-tkl {
  background-image: url(/images/flags/4x3/tkl.png);
}

.flag-icon-tkm {
  background-image: url(/images/flags/4x3/tkm.png);
}

.flag-icon-tls {
  background-image: url(/images/flags/4x3/tls.png);
}

.flag-icon-ton {
  background-image: url(/images/flags/4x3/ton.png);
}

.flag-icon-tto {
  background-image: url(/images/flags/4x3/tto.png);
}

.flag-icon-tun {
  background-image: url(/images/flags/4x3/tun.png);
}

.flag-icon-tur {
  background-image: url(/images/flags/4x3/tur.png);
}

.flag-icon-tuv {
  background-image: url(/images/flags/4x3/tuv.png);
}

.flag-icon-twn {
  background-image: url(/images/flags/4x3/twn.png);
}

.flag-icon-tza {
  background-image: url(/images/flags/4x3/tza.png);
}

.flag-icon-uga {
  background-image: url(/images/flags/4x3/uga.png);
}

.flag-icon-ukr {
  background-image: url(/images/flags/4x3/ukr.png);
}

.flag-icon-umi {
  background-image: url(/images/flags/4x3/umi.png);
}

.flag-icon-ury {
  background-image: url(/images/flags/4x3/ury.png);
}

.flag-icon-usa {
  background-image: url(/images/flags/4x3/usa.png);
}

.flag-icon-uzb {
  background-image: url(/images/flags/4x3/uzb.png);
}

.flag-icon-vat {
  background-image: url(/images/flags/4x3/vat.png);
}

.flag-icon-vct {
  background-image: url(/images/flags/4x3/vct.png);
}

.flag-icon-ven {
  background-image: url(/images/flags/4x3/ven.png);
}

.flag-icon-vgb {
  background-image: url(/images/flags/4x3/vgb.png);
}

.flag-icon-isv {
  background-image: url(/images/flags/4x3/isv.png);
}

.flag-icon-vnm {
  background-image: url(/images/flags/4x3/vnm.png);
}

.flag-icon-vut {
  background-image: url(/images/flags/4x3/vut.png);
}

.flag-icon-wal {
  background-image: url(/images/flags/4x3/wal.png);
}

.flag-icon-wlf {
  background-image: url(/images/flags/4x3/wlf.png);
}

.flag-icon-wsm {
  background-image: url(/images/flags/4x3/wsm.png);
}

.flag-icon-yem {
  background-image: url(/images/flags/4x3/yem.png);
}

.flag-icon-rsa {
  background-image: url(/images/flags/4x3/rsa.png);
}

.flag-icon-zmb {
  background-image: url(/images/flags/4x3/zmb.png);
}

.flag-icon-zwe {
  background-image: url(/images/flags/4x3/zwe.png);
}